<template>
  <div class="profile-page">
    <active-tab :activeTab="activeTab"/>
    <div class="bottom-side">
      <div v-if="!isEditing">
        <div class="profile-header">
            <div class="profile-info">
              <!-- <div class="profile-picture">
                <span class="material-symbols-rounded">photo_camera</span>
              </div> -->
              <div class="profile-name">
                <p class="p-title">{{ profileData.firstName }}</p>
                <p class="p-name">{{ profileData.companyName }}</p>
                <a class="role-tag">
                  <span class="material-symbols-rounded">work</span>
                  <p class="p-role">{{ profileData.role }}</p>
                </a>
              </div>
            </div>
          </div>
          <div class="profile-details">
            <div class="profile-row">
              <div class="profile-item">
                <p>Lawyer Name</p>
                <span>{{ profileData.fullName }}</span>
              </div>
              <!-- <div class="profile-item">
                <p>Last Name</p>
                <span v-if="profileData.lastName">{{ profileData.lastName }}</span>
                <span v-else>-</span>
              </div> -->
            </div>
            <div class="profile-row">
              <div class="profile-item">
                <p>Lawyer Email</p>
                <span>{{ profileData.personalEmail }}</span>
              </div>
              <div class="profile-item">
                <p>Law Firm Contact Number</p>
                <span>{{ profileData.contactNumber }}</span>
              </div>
            </div>
            <div class="profile-row">
              <div class="profile-item">
                <p>Law Firm Name</p>
                <span>{{ profileData.lawFirm }}</span>
              </div>
              <div class="profile-item">
                <p>Role</p>
                <span>{{ profileData.role }}</span>
              </div>
            </div>
            <!-- <div class="profile-row">
              <div class="profile-item">
                <p>Firm Email Address</p>
                <span>{{ profileData.firmEmail }}</span>
              </div>
              <div class="profile-item">
                <p>Firm Contact No.</p>
                <span>{{ profileData.firmContact }}</span>
              </div>
            </div> -->
            <div class="profile-row">
              <div class="profile-item full">
                <p>Firm Address</p>
                <span>{{ profileData.firm_address_1 }}</span> <br>
                <span>{{ profileData.firm_address_2 }}</span>
              </div>
            </div>
          </div>
        <div class="edit">
          <button class="edit-button" @click="toggleEdit">Edit Profile</button>
        </div>
      </div>
      
      <div v-if="isEditing">
        <PageLoader
          style="margin-top: 150px;"
          v-if="pageLoading"
          :loading="pageLoading">
        </PageLoader>
        <div v-else>
          <div class="profile-header" style="margin:0px;">
            <div class="profile-info">
              <!-- <div class="profile-picture">
                <span class="material-symbols-rounded">photo_camera</span>
              </div> -->
              <div class="profile-name">
                <p class="p-title">{{ profileData.firstName }}</p>
                <p class="p-name">{{ profileData.companyName }}</p>
              </div>
            </div>
          </div>
          <div class="profile-details">
            <b-container style="padding: 0px;">
              <b-row class="profile-row">
                <b-col cols="12" class="profile-item mb-3">
                  <p class="form-text">Lawyer Name</p>
                    <b-form-input 
                    class="input-form-address" type="text" 
                    v-model="profileData.fullName" 
                    required
                    />
                </b-col>
              </b-row>
              <b-row class="profile-row">
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Lawyer Email</p>
                    <b-form-input 
                      class="input-form" type="text" 
                      v-model="profileData.personalEmail" 
                      required
                    />
                </b-col>
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Law Firm Contact Number</p>
                    <b-form-input 
                      class="input-form" type="text" 
                      v-model="profileData.contactNumber" 
                      required
                    />
                </b-col>
              </b-row>
              <b-row class="profile-row">
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Law Firm Name</p>
                    <b-form-input 
                      class="input-form" type="text" 
                      v-model="profileData.lawFirm" 
                      required
                    />
                </b-col>
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Role</p>
                  <b-form-input 
                    class="input-form" type="text" 
                    v-model="profileData.role" 
                    required
                    readonly
                  />
                </b-col>
              </b-row>
              <!-- <b-row class="profile-row">
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Firm Email Address</p>
                  <b-form-input 
                    class="input-form" type="text" 
                    v-model="profileData.firmEmail" 
                    required
                  />
                </b-col>
                <b-col cols="6" class="profile-item mb-3">
                  <p class="form-text">Firm Contact No.</p>
                  <b-form-input 
                    class="input-form" type="text" 
                    v-model="profileData.firmContact" 
                    required
                  />
                </b-col>
              </b-row> -->
              <b-row class="profile-row">
                <b-col cols="12" class="profile-item mb-3">
                  <p class="form-text">Firm Address (Line 1)</p>
                  <b-form-input 
                    class="input-form-address" type="text" 
                    v-model="profileData.firm_address_1" 
                    required
                  />
                </b-col>
              </b-row>
              <b-row class="profile-row">
                <b-col cols="12" class="profile-item mb-3">
                  <p class="form-text">Firm Address (Line 2)</p>
                    <b-form-input 
                      class="input-form-address" type="text" 
                      v-model="profileData.firm_address_2" 
                      required
                    />
                </b-col>
              </b-row>
              <b-row class="profile-row mb-3">
                <b-col class="mt-2" style="margin-left: 475px">
                  <button class="cancel-button me-3" @click="toggleEdit">Cancel</button>
                  <button class="update-button" @click="saveChanges">Update Changes</button>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/store/Auth.js";
import activeTab from "./activeTab.vue";
import PageLoader from "../../Pages/PageLoader.vue";


export default {
  components: {
    activeTab,
    PageLoader,
  },

  data() {
    return {
      activeTab: 'profile',
      profileData: {
        fullName: '',
        companyName: '',
        firstName: '',
        lastName: '',
        personalEmail: '',
        contactNumber: '',
        lawFirm: '',
        role: '',
        firmEmail: '',
        firmContact: '',
        firmAddress: '',
        email: ''
      },
      isChangingPassword: false,
      currentPassword: '',
      passwordVisible: false,
      newPassword: '',
      confirmPassword: '',
      extraInfo: true,
      website: "www.example.com",
      name: JSON.parse(localStorage.userInfo).name,
      email: JSON.parse(localStorage.userInfo).email,
      form: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },

      extra_info: {
        contact_number: " ",
        firm_address_1: " ",
        firm_address_2: " ",
        firm_address_3: " ",
        company_name: JSON.parse(localStorage.userInfo).extra_info.company_name,
      },
      isEditing: false,
      addClient: false,
      pageLoading: false,
    }
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisible ? 'text' : 'password';
    },
    hasSaasClientPermission() {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const parsedUserInfo = JSON.parse(userInfo);
        return parsedUserInfo.permissions && parsedUserInfo.permissions.includes('Saas-Client.index');
      }
      return false;
    }
  },
  methods: {
    back() {
      this.addClient = false;
    },
    toggleClient() {
      this.addClient = !this.addClient;
    },
    togglePasswordChange() {
      this.isChangingPassword = !this.isChangingPassword;
    },
    // updatePassword() {
    //   if (this.newPassword === this.confirmPassword) {
    //     alert('Password updated successfully');
    //   } else {
    //     alert('Passwords do not match');
    //   }
    // },
    toggleVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    saveChanges() {
      switch("") {
        case this.profileData.lawFirm:
        case this.profileData.firm_address_1:
        case this.profileData.contactNumber:
          this.$toast.error("Please fill in all fields");
          return;
      }
      switch(null) {
        case this.profileData.lawFirm:
        case this.profileData.firm_address_1:
        case this.profileData.contactNumber:
          this.$toast.error("Please fill in all fields");
          return;
      }

      this.profileData.firm_address_2 = this.profileData.firm_address_2 == undefined
        ? "" : this.profileData.firm_address_2;
      this.profileData.firm_address_3 = this.profileData.firm_address_3 == undefined
        ? "" : this.profileData.firm_address_3;

      Auth.updateProfile({
        name: this.profileData.fullName,
        email: this.profileData.personalEmail,
        extra_info: {
          company_name: this.profileData.lawFirm,
          contact_number: this.profileData.contactNumber,
          address: [
            this.profileData.firm_address_1,
            this.profileData.firm_address_2,
          ].join("\n"),
        },
      })

        .then(async (response) => {
          try {
            this.$toast.info("Data is being updated...");
            this.pageLoading = true;
            const res = await Auth.getMe();
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            this.$toast.success(response.data.message);
            this.pageLoading = false;
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePassword() {
      Auth.changePassword(this.form)
        .then((response) => {
          this.$toast.success(response.data.message);

          this.$bvModal.hide("modal-center-change-password");
          this.form = {
            old_password: "",
            password: "",
            password_confirmation: "",
          };

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
  // Assuming the user info is stored in localStorage as `userInfo`
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  if (userInfo && userInfo.extra_info) {
    const fullNameArray = userInfo.name.split(' ');

    // Assign firstName as the first part before the space and lastName as the rest
    const firstName = fullNameArray[0];
    const lastName = fullNameArray.slice(1).join(' '); // Handles names with multiple spaces
    const addressParts = userInfo.extra_info.address?.split("\n") ?? ['','',''];

    this.profileData = {
      fullName: userInfo.name,
      companyName: userInfo.extra_info.company_name || '',
      firstName: firstName || '', // First name
      lastName: lastName || '',   // Last name (everything after the first space)
      personalEmail: userInfo.email || '',
      contactNumber: userInfo.extra_info.contact_number || '',
      lawFirm: userInfo.extra_info.company_name || '',
      role: userInfo.is_manager ? 'Manager' : 'User',
      firmEmail: userInfo.email || '',
      firmContact: userInfo.extra_info.contact_number || '',
      firm_address_1: addressParts[0] || '',
      firm_address_2: addressParts[1] || '',
      firm_address_3: addressParts[2] || '',
      email: userInfo.email || ''
    };
  }
}

}
</script>
<style scoped>
/* General Page Styling */
.profile-page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-content{
  padding: 0;
}

.bottom-side {
  height: 89.31%;
  padding: 40px;
}

.profile-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.profile-info {
  display: flex;
  align-items: flex-end;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  span{
    font-variation-settings: 'FILL' 1;
    background: #E7E7E7;
    font-size:18px;
  }
}

.profile-name {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.p-title{
  font-size: 24px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}
p-name{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--dark-grey);
}

.role-tag {
  display: flex;
  align-items: center;
  text-decoration: none;
  span{
    margin-right: 5px;
  }
}
.p-role{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--status-blue) !important;
  margin: 0;
}
/* Profile Details Styling */
/* General profile details layout */
.input-form {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-Light-Grey, #F2F3F3);
}

.input-form-address {
  display: flex;
  width: 750px;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-Light-Grey, #F2F3F3);
}

.form-text{
  color: var(--Neutral-Dark-Gray, #A9A7A7) !important;
  margin: 4px !important;
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-row {
  display: flex;
  justify-content: flex-start;
}

.profile-item {
  flex: none; /* Prevent items from expanding */
  width: 33%; /* Adjust percentage width of each item */
}

.profile-item.full {
  width: 100%; /* For items that should take the full width */
}

.profile-item p {
  margin: 0;
  font-size: 14px;
  color: #A9A7A7;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.profile-item span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--neutral-black);
}


/* Edit Button Styling */
.edit {
  display: flex;
  flex-direction: start;
  margin-top: 40px;
}

.cancel-button{
  color: var(--Primary-Dark-Blue, #000046);
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Mid-Grey, #D1D2D5);
  background: var(--Neutral-White, #FFF);
}

.cancel-button:hover{
  background: var(--Neutral-Light-Grey, #F2F3F3);
}

.update-button{
  background-color: var(--primary);
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.update-button:hover{
  background: var(--Primary-Dark-Blue, #000046);
}

.edit-button {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background: var(--Primary-Dark-Blue, #000046);
}
</style>
<style scoped>
/* Header style */
.email-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.email-header span {
  font-size: 24px;
  margin-right: 10px;
  font-variation-settings: 'FILL' 1;
}

.email-header p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--neutral-black);
}

/* Email and password info */
.email-info, .password-section {
  margin-bottom: 20px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #A9A7A7;
  margin-bottom: 8px;
}

.email {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-black);
}

.change-password-btn {
  width: 217px;
  background-color: var(--primary);
  color: #FFFFFF;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.change-password-btn:hover {
  background-color: #003366;
}
</style>

<style scoped>
/* Header */
.password-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.password-header span {
  font-size: 24px;
  margin-right: 10px;
  font-variation-settings: 'FILL' 1;
}

.password-header p {
  font-size: 24px;
  font-weight: 600;
  font-weight: bold;
  color: var(--neutral-black);
  margin: 0;
}

/* Form fields */
.input-field {
  margin-bottom: 20px;
}

.input-field label {
  font-weight: 400;
  font-size: 14px;
  color: #a9a7a7;
  margin-bottom: 8px;
  display: block;
}

.input-field input {
  width: 350px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--light-grey);
  font-size: 14px;
  outline: none;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 350px;
}

.password-input-wrapper input {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--light-grey);
  font-size: 14px;
  outline: none;
  padding-right: 40px; 
}

.toggle-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: var(--dark-grey);
}


/* Buttons */
.button-group {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  gap: 10px;
}

.cancel-btn {
  background-color: #FFFFFF;
  color: var(--primary);
  border: 1px solid var(--mid-grey);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.update-btn {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.update-btn:hover {
  background-color: #003366;
}
</style>